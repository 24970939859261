import { CssBaseline } from "@material-ui/core";
import { Loading, PollOrder, PollTerra, SaturnSnackbar } from "components";
import { Gateway } from "pages";
import { SaturnTheme, WalletProvider } from "providers";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import "theme/App.css";

export const queryClient = new QueryClient();

function App() {
  return (
    <React.Fragment>
      <RecoilRoot>
        <SaturnTheme>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <WalletProvider>
                <PollOrder />
                <PollTerra />
                <Gateway />
                <SaturnSnackbar />
                <CssBaseline />
              </WalletProvider>
            </QueryClientProvider>
          </Suspense>
        </SaturnTheme>
      </RecoilRoot>
    </React.Fragment>
  );
}

export default App;
