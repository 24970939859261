import {useEffect, Dispatch, useState} from 'react'

/**
 *
 * @param key The key to set in localStorage for this value
 * @param defaultValue The value to use if it is not already in localStorage
 */

type ReturnType<T> = [
  T | undefined,
  Dispatch<React.SetStateAction<T | undefined>>,
]

const useLocalStorage = <T>(key: string, initialValue?: T): ReturnType<T> => {
  const [state, setState] = useState<T | undefined>(() => {
    if (initialValue === null || undefined) return
    try {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : initialValue
    } catch (err) {
      return initialValue
    }
  })

  useEffect(() => {
    if (state !== null || undefined) {
      try {
        localStorage.setItem(key, JSON.stringify(state))
      } catch (err) {
        throw err
      }
    }
  }, [state, key])

  return [state, setState]
}

export default useLocalStorage
