import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IconArrowLeft, LogoSaturn } from "assets";
import { orderState, orderTotalState, redirectUrlState } from "atoms";
import clsx from "clsx";
import numeral from "numeral";
import React from "react";
import { useRecoilValue } from "recoil";
import { palette } from "theme";
import { cssPayPage } from "theme/styles";
import { SaturnGradientPaper } from "ui";

function PaySummary() {
  const order = useRecoilValue(orderState);
  const redirectParam = useRecoilValue(redirectUrlState);
  const orderTotal = useRecoilValue(orderTotalState);
  const { status, redirect_uri, logo } = order;

  const amount = order.amount / 1000000;
  const total = orderTotal / 1000000;

  const classes = cssPayPage();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const testnet = process.env.REACT_APP_BUILD_ENV === "testnet";

  return (
    <SaturnGradientPaper>
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box>
          <Grid container spacing={sm ? 2 : 4}>
            {testnet && (
              <Grid item xs={12}>
                <Chip label="Testnet" className={classes.chipPurple} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                className={classes.merchantButton}
                onClick={() =>
                  (window.location.href = redirectParam
                    ? redirectParam
                    : redirect_uri)
                }
              >
                <IconArrowLeft style={{ fill: palette.text.light.secondary }} />
                {logo && (
                  <img
                    src={`${logo}?${Date.now()}`}
                    height="40"
                    width="auto"
                    alt={order.merchant}
                    style={{ borderRadius: 4 }}
                  />
                )}
                <Typography>{order.merchant}</Typography>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Total
              </Typography>
              <Typography className={classes.total}>
                {numeral(total).format("0,0.00")}{" "}
                <Typography variant="caption">
                  {order.currency.slice(0, -1)}T
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Fee
              </Typography>
              <Typography>
                {numeral(total - amount).format("0,0.00")}{" "}
                <Typography variant="caption">
                  {order.currency.slice(0, -1)}T
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: 4 }}
              >
                Status
              </Typography>
              <Chip
                label={order.status.toUpperCase()}
                size="small"
                className={clsx(
                  status === "unpaid" && classes.chipOrange,
                  status === "paid" && classes.chipTeal,
                  status === "failed" && classes.chipRed
                )}
              />
            </Grid>

            {status === "unpaid" && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={16} style={{ marginRight: 16 }} />
                  <Typography variant="caption">Waiting for Payment</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <Box mt={4} mb={1}>
            <Typography variant="caption" display="block" color="textSecondary">
              Powered By
            </Typography>
          </Box>
          <Link
            href="https://saturn.money/atlas"
            target="_blank"
            title="Visit Saturn.Money"
          >
            <LogoSaturn
              width={100}
              height={22}
              style={{ fill: theme.palette.text.primary }}
            />
          </Link>
        </Box>
      </Box>
    </SaturnGradientPaper>
  );
}

export default PaySummary;
