import { Paper, withTheme } from "@material-ui/core";
import styled from "styled-components";
import { palette } from "theme";

//props.theme.palette.type === 'light' ? 'red' : 'blue'
const StyledPaper = withTheme(styled(Paper)`
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    ${(props) =>
      props.theme.palette.type === "dark"
        ? `rgba(255, 255, 255, 0.02) 0%`
        : `rgba(255,255,255,1) 100%`},
    ${(props) =>
      props.theme.palette.type === "dark"
        ? `rgba(255, 255, 255, 0.05) 100%`
        : `rgba(255,255,255,1) 100%`}
  );
  box-shadow: ${(props) =>
    props.theme.palette.type === "dark"
      ? palette.shadows.paper.dark
      : palette.shadows.paper.light};
  border-radius: 24px;
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
`);
export default StyledPaper;
