import React, { SVGProps } from "react";

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="inherit" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="inherit"
          fillRule="nonzero"
          d="M9.928 2.005a.533.533 0 00-.417.322l-1.066 2.49a.533.533 0 10.977.416L10 3.883l.578 1.35h-.001a.53.53 0 00.716.324.533.533 0 00.261-.74l-1.066-2.49a.533.533 0 00-.56-.322zM4.442 3.96a.534.534 0 00-.44.739L5.07 7.19a.533.533 0 10.977-.417l-.544-1.267 1.266.544a.535.535 0 10.422-.984L4.703 4a.534.534 0 00-.261-.04zm10.999 0a.52.52 0 00-.144.039L12.81 5.066h-.001a.535.535 0 00.422.984l1.266-.545-.544 1.267a.532.532 0 00.69.737.534.534 0 00.288-.32l1.066-2.49a.533.533 0 00-.556-.738zM9.999 5.917a4.096 4.096 0 00-4.086 4.09 4.096 4.096 0 004.086 4.089 4.096 4.096 0 004.087-4.09A4.096 4.096 0 0010 5.917zm0 1.067a3.014 3.014 0 013.021 3.022A3.014 3.014 0 0110 13.03a3.014 3.014 0 01-3.022-3.023A3.014 3.014 0 0110 6.984zm.156.355a.534.534 0 00.05 1.067c.385-.019.708.148.972.489.265.34.439.855.422 1.45a.533.533 0 001.066.028c.023-.819-.21-1.57-.649-2.134-.439-.564-1.11-.936-1.86-.9h-.001zM5.008 8.4a.533.533 0 00-.194.045L2.326 9.51a.533.533 0 000 .984l2.488 1.067a.534.534 0 10.421-.983l-1.343-.573 1.343-.577v-.001A.533.533 0 005.008 8.4zm9.916.356h.001a.533.533 0 00-.161 1.028l1.344.577-1.344.573a.535.535 0 10.422.984l2.488-1.067a.534.534 0 000-.984L15.186 8.8a.528.528 0 00-.261-.044zm-9.367 3.728a.532.532 0 00-.488.334l-1.066 2.49a.534.534 0 00.7.7L7.19 14.94a.535.535 0 00-.422-.983l-1.266.539.545-1.261a.533.533 0 00-.49-.75zm8.873 0a.532.532 0 00-.476.75l.544 1.261-1.266-.539v.001a.536.536 0 00-.422.983l2.487 1.067a.533.533 0 00.7-.7l-1.066-2.49a.534.534 0 00-.5-.333h-.001zm-3.37 1.923a.533.533 0 00-.483.367L10 16.124l-.577-1.35a.532.532 0 10-.977.416l1.066 2.49a.533.533 0 00.977 0l1.066-2.49a.533.533 0 00-.494-.783z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
