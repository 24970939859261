import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IconCopy } from "assets";
import { orderState, orderTotalState, snackbarState } from "atoms";
import numeral from "numeral";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { palette } from "theme";
import { cssPayPage } from "theme/styles";
import { SaturnButton, SaturnGradientPaper } from "ui";
var QRCode = require("qrcode.react");

function PayManually() {
  const classes = cssPayPage();

  // Atoms
  const setSnack = useSetRecoilState(snackbarState);
  const order = useRecoilValue(orderState);
  const total = useRecoilValue(orderTotalState);
  const amount = total / 1000000;

  const handleCopy = (text: any, label: string) => {
    navigator.clipboard.writeText(text.toString());
    setSnack({ severity: "info", message: `Copied ${label}` });
  };

  // Theming Hooks
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const small = useMediaQuery(theme.breakpoints.between(960, 1086));

  function TextLabel({ label }: { label: string }) {
    return (
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
    );
  }

  function ValueButton({
    label,
    value,
    formattedValue,
  }: {
    label: string;
    value: any;
    formattedValue?: string;
  }) {
    return (
      <Tooltip title="Copy">
        <SaturnButton
          variant="outlined"
          color="default"
          onClick={() => handleCopy(value, `${label}`)}
          style={{
            textAlign: sm ? "left" : "center",
            marginLeft: -14,
          }}
        >
          <Typography
            variant="body2"
            style={{
              wordBreak: md ? "break-word" : "normal",
              textTransform: "lowercase",
              marginRight: 16,
              fontSize: small ? 14 : 16,
            }}
          >
            {formattedValue ? formattedValue : value}
          </Typography>
          <IconCopy className={classes.icon} />
        </SaturnButton>
      </Tooltip>
    );
  }

  function Merchant() {
    return (
      <Box mb={2}>
        <TextLabel label="Merchant" />
        <ValueButton label="Wallet Address" value={order.wallet} />
      </Box>
    );
  }

  function Amount() {
    return (
      <Box mb={2}>
        <TextLabel label="Amount" />
        <ValueButton
          label="Amount"
          value={amount}
          formattedValue={`${numeral(amount).format(
            "0,0.00"
          )} ${order.currency.slice(0, -1)}T`}
        />
      </Box>
    );
  }

  function Memo() {
    return (
      <Box mb={2}>
        <TextLabel label="Memo" />
        <ValueButton label="Memo" value={order.id} />
      </Box>
    );
  }

  return (
    <SaturnGradientPaper>
      <Box p={4}>
        <Box mb={2}>
          <Typography variant="body1">Manual Transfer</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection={sm ? "column" : "row"}
          justifyContent="space-between"
          alignItems={sm ? "flex-start" : "center"}
        >
          <Box>
            <Merchant />
            <Amount />
            <Memo />
          </Box>
          <Box>
            <QRCode
              value={order.wallet}
              size={small ? 80 : 120}
              bgColor={theme.palette.background.default}
              fgColor={theme.palette.text.primary}
            />
          </Box>
        </Box>

        <Box mt={2}>
          <Box
            p={2}
            mt={2}
            style={{
              background: theme.palette.background.default,
              borderRadius: 8,
              border:
                theme.palette.type === "dark"
                  ? "none"
                  : `1px solid ${theme.palette.text.secondary}20`,
            }}
          >
            <Typography
              color="inherit"
              style={{
                color:
                  theme.palette.type === "dark"
                    ? palette.accents.orange.main
                    : palette.accents.orange.dark,
              }}
            >
              ⚠️ These details MUST match when making a manual payment.
            </Typography>
          </Box>
        </Box>
      </Box>
    </SaturnGradientPaper>
  );
}

export default PayManually;
