export const palette = {
  accents: {
    teal: {
      main: "#0BECC0",
      light: "#7AF0CD",
      dark: "#19E3A7",
    },
    orange: {
      main: "#FEB205",
      light: "#feca2a",
      dark: "#fe9003",
    },
    red: {
      main: "#FF3E49",
      light: "#f3686e",
      dark: "#f6052a",
    },
    lightRed: {
      main: "#ff452c",
      light: "#ffc7c0",
      dark: "#ff2e1b",
    },
    blue: {
      main: "#246BFD",
      light: "#0DA3E7",
      dark: "#3533ca",
    },
    lightBlue: {
      light: "#bcccf0",
      main: "#2056CD",
      dark: "#133cba",
    },
    black: {
      main: "#050916",
      light: "#050916",
      dark: "#050916",
    },
    white: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
    },
    green: {
      light: "#b3f0cd",
      main: "#00CE58",
      dark: "#00bc3d",
    },
  },

  common: {
    background: "#050916",
    white: "#FFFFFF",
    border: "#2A2C35",
  },
  text: {
    light: {
      primary: "#050916",
      secondary: "#696B73",
      disabled: "#CDCED0",
      hint: "#9B9DA2",
    },
    dark: {
      primary: "#FFFFFF",
      secondary: "#A0A1A5",
      disabled: "#A59DAD",
      hint: "#7E7E7E",
    },
  },
  shadows: {
    paper: {
      dark: "0 -8px 24px 4px #050916",
      light: "0 0 8px 0 rgba(5,9,22,0.08)",
    },
  },
};
