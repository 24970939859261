import { atom } from "recoil";
import Saturn from "typescript";

export const paletteState = atom<Saturn.types.Themes>({
  key: "paletteState", // unique ID (with respect to other atoms/selectors)
  default: "dark", // default value (aka initial value)
});

export const snackbarState = atom<Saturn.interfaces.Snackbar>({
  key: "snackbarState",
  default: {
    severity: undefined,
    message: "",
  },
});

export const orderIdState = atom<string | undefined>({
  key: "orderIdState",
  default: undefined,
});

export const redirectUrlState = atom<string | undefined>({
  key: "orderRedirectUrlState",
  default: undefined,
});

export const orderRefetchIntervalState = atom<number | false>({
  key: "orderRefetchIntervalState",
  default: 2500,
});

export const orderState = atom<Saturn.models.Order>({
  key: "orderState",
  default: {
    amount: 0,
    created: {
      _nanoseconds: 0,
      _seconds: 0,
    },
    webhook_url: "",
    wallet: "",
    status: "init",
    redirect_uri: "",
    merchant_order_id: 0,
    merchant: "atlas",
    id: "",
    denom: "eut",
    currency: "eur",
  },
});

export const orderFeeState = atom<Saturn.models.Fee>({
  key: "orderFeeState",
  default: {
    gas: 140000,
    gasPrice: 0,
    amount: 0,
    tax: 0,
  },
});

export const orderTotalState = atom<number>({
  key: "orderTotal",
  default: 0,
});

export const orderErrorState = atom<Saturn.types.TxError | undefined>({
  key: "orderError",
  default: undefined,
});

export const awaitingConfirmationState = atom<boolean>({
  key: "awaitingConfirmation",
  default: false,
});
