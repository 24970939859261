import { makeStyles, Theme } from "@material-ui/core";
import { palette } from "theme";

export const cssPayPage = makeStyles((theme: Theme) => ({
  merchantButton: {
    marginLeft: -8,
    "& .MuiButton-label > *": {
      marginRight: 8,
    },
    textTransform: "initial",
    color: palette.text.light.secondary,
  },
  chipPrimary: {
    backgroundColor: `rgba(255,255,255,0.2) !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: 4,
  },
  chipBlue: {
    backgroundColor: `${palette.accents.blue.light} !important`,
    color: `${palette.accents.black.main} !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
  },
  chipPurple: {
    backgroundColor: `#3533ca !important`,
    color: `#FFF !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
  },
  chipOrange: {
    backgroundColor: `${palette.accents.orange.main} !important`,
    color: `${palette.accents.black.main} !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
  },
  chipTeal: {
    backgroundColor: `${palette.accents.teal.main} !important`,
    color: `${palette.accents.black.main} !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
  },
  chipRed: {
    backgroundColor: `${palette.accents.red.main} !important`,
    color: `${palette.accents.black.main} !important`,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1,
  },
  total: {
    fontSize: 32,
    fontFamily: [
      "SF Pro Display",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& > span": {
      fontWeight: 600,
    },
  },
  icon: {
    fill: palette.text.light.secondary,
  },
  switch: {
    position: "absolute",
    top: 40,
    right: 40,
    zIndex: 999,
  },
}));

export const cssGridPrimary = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    minHeight: "100vh",
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  summary: {
    flex: 1,
    maxWidth: 360,
    width: "100%",
    display: "flex",
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginRight: 0,
      marginBottom: 32,
    },
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    flex: 2,
  },
  fullWidth: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
  },
}));
