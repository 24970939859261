import { useLocalStorage } from "hooks";
import React, { createContext, useCallback, useEffect } from "react";
import { extension } from "terra";
import Saturn from "typescript";

export const WalletContext = createContext<Saturn.providers.Wallet>({
  address: undefined,
  connect: () => {
    /* connect */
  },
  disconnect: () => {
    /* disconnect */
  },
  install: () => {
    /* install */
  },
  glance: () => {
    /* install */
  },
  installed: undefined,
});

export const WalletProvider: React.FC = ({ children }) => {
  const init = extension.init;
  const [installed, setInstalled] = useLocalStorage("atlas::extension", init);
  const install = () => setInstalled(true);

  const [address, setAddress] = useLocalStorage("atlas::address", "");
  const glance = setAddress;

  const connect = useCallback(async () => {
    const response = await extension.connect();
    if (response && response.address) {
      setAddress(response.address);
    }
  }, [setAddress]);

  const disconnect = () => setAddress(undefined);

  useEffect(() => {
    address && connect();
  }, [address, connect]);

  return (
    <WalletContext.Provider
      value={{
        address,
        installed,
        install,
        glance,
        connect,
        disconnect,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
