import { CircularProgress, Fade, useTheme } from "@material-ui/core";
import React from "react";

function Loading() {
  const theme = useTheme();

  return (
    <Fade in={true} timeout={5000} mountOnEnter unmountOnExit>
      <CircularProgress
        size={80}
        color="inherit"
        style={{
          color: theme.palette.primary.main,
        }}
      />
    </Fade>
  );
}

export default Loading;
