import {useQuery} from 'react-query'
import Saturn from 'typescript'

function useOrder(
  orderId: string | undefined,
  enabled: boolean = false,
  refetchInterval: number | false = 5000,
) {
  return useQuery<Saturn.models.Order, Error>(
    'order',
    () =>
      fetch(
        `${process.env.REACT_APP_ATLAS_API_ENDPOINT}/order?order_id=${orderId}`,
      ).then((r) => r.json()),
    {
      enabled,
      refetchOnWindowFocus: true,
      refetchInterval,
    },
  )
}

export default useOrder
