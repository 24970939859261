import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { snackbarState } from "atoms";
import React from "react";
import { useRecoilState } from "recoil";

function SaturnSnackbar() {
  const [snack, setSnack] = useRecoilState(snackbarState);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({
      message: "",
      severity: undefined,
    });
  };

  return (
    <Snackbar
      open={snack.message.length > 0}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      key={snack.message}
    >
      <Alert
        severity={snack.severity}
        onClose={handleClose}
        icon={false}
        variant="filled"
      >
        {snack.message}
      </Alert>
    </Snackbar>
  );
}

export default SaturnSnackbar;
