import React, {SVGProps} from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="inherit" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="inherit"
          fillRule="nonzero"
          d="M17.707 10.653a.865.865 0 000-1.296l-4.34-4.081A.938.938 0 0012.683 5c-.267 0-.499.092-.694.276a.848.848 0 00-.282.642c0 .252.094.47.282.653l2.68 2.51H2.977c-.268 0-.5.092-.695.276A.848.848 0 002 10c0 .252.094.47.282.653a.998.998 0 00.695.265h11.692l-2.68 2.521a.848.848 0 00-.282.643c0 .252.094.47.282.653a.998.998 0 00.694.265.959.959 0 00.684-.265l4.34-4.082z"
          transform="matrix(-1 0 0 1 20 0)"
        ></path>
      </g>
    </svg>
  )
}

export default Icon
