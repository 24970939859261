import React, { SVGProps } from "react";

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="inherit" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="inherit"
          fillRule="nonzero"
          d="M8.62 2c.221 0 .387.11.497.276.11.165.11.386 0 .552-1.935 3.01-.99 5.81.49 7.33l.117.118c1.49 1.49 4.359 2.593 7.448.607a.501.501 0 01.552 0 .58.58 0 01.276.496C18 14.58 14.91 18 10.276 18 5.53 18 2 14.469 2 9.724 2 5.09 5.42 2 8.62 2zM6.954 3.863l-.112.05c-1.87.884-3.341 3.1-3.341 5.811 0 3.917 2.86 6.776 6.776 6.776 2.711 0 4.927-1.472 5.81-3.341l.05-.112-.174.06c-2.628.839-5.252.153-7.103-1.58l-.176-.171C6.76 9.5 6 6.752 6.909 3.992l.044-.129z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
