import {createMuiTheme} from '@material-ui/core'
import {palette} from 'theme'

const muiTheme = createMuiTheme()

export const shadows = muiTheme.shadows

export const overrides = {
  MuiAutocomplete: {
    paper: {
      background: palette.common.background,
      borderRadius: 8,
      boxShadow: '0 0 10px 0 rgba(11,236,192,0.20)',
    },
    option: {
      fontWeight: 600,
      '&[data-focus="true"]': {
        color: palette.accents.orange.main,
      },
    },
    endAdornment: {
      '& svg': {
        fill: 'rgba(255,255,255,0.4)',
      },
    },
  },
  MuiLinearProgress: {
    root: {
      borderRadius: 99,
    },
    bar: {
      borderRadius: 99,
    },
    colorPrimary: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    barColorPrimary: {
      backgroundColor: palette.accents.teal.light,
    },
    colorSecondary: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  MuiTab: {
    root: {
      fontWeight: 600,
    },
  },
}

export const typography = {
  fontFamily: [
    'raleway',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 16,
  h1: {},
  h2: {},
  h3: {
    fontWeight: 800,
    fontSize: 24,
  },
  h4: {
    fontWeight: 700,
    fontSize: 20,
  },
  h5: {},
  h6: {},
  body1: {
    fontWeight: 500,
  },
  body2: {
    fontWeight: 500,
  },
  overline: {},
  button: {},
  caption: {},
}
