import {Paper, withTheme} from '@material-ui/core'
import styled from 'styled-components'
import {palette} from 'theme'

const TethysPaper = withTheme(styled(Paper)`
  background: ${(props) =>
    props.theme.palette.type === 'dark'
      ? `rgba(255, 255, 255, 0.05)`
      : 'rgba(255, 255, 255, 1)'};
  box-shadow: ${(props) =>
    props.theme.palette.type === 'dark'
      ? palette.shadows.paper.dark
      : palette.shadows.paper.light};
  border-radius: 24px;
  backdrop-filter: blur(4px);
  transition: max-height 0.5s ease-in-out;
`)
export default TethysPaper
