import { LCDClient } from "@terra-money/terra.js";
import {
  orderErrorState,
  orderFeeState,
  orderState,
  orderTotalState,
} from "atoms";
import axios, { AxiosResponse } from "axios";
import { WalletContext } from "providers";
import { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

function PollTerra() {
  // Hook Into Wallet
  const { address: userWallet } = useContext(WalletContext);

  // Atoms
  const order = useRecoilValue(orderState);
  const [fees, setFees] = useRecoilState(orderFeeState);
  const [total, setTotal] = useRecoilState(orderTotalState);
  const setError = useSetRecoilState(orderErrorState);

  // Create New Terra Instance for Blockchain Interaction
  const terra = new LCDClient({
    URL: process.env.REACT_APP_TERRA_URL as string,
    chainID: process.env.REACT_APP_TERRA_CHAINID as string,
  });

  useEffect(() => {
    if (fees.gasPrice !== 0 || order.status === "init") return;
    axios
      .get(process.env.REACT_APP_TERRA_FEE_API as string)
      .then((v: AxiosResponse<{ [key: string]: string }>) => {
        const gasPrice = Number(v.data[order.denom]);
        setFees({ ...fees, gasPrice });
      });
  }, [fees, order, setFees]);

  // Set Fee
  useEffect(() => {
    if (order.amount === 0) return;
    if (fees.gasPrice === 0) return;
    if (fees.amount !== 0) return;

    setFees({
      ...fees,
      amount: Math.ceil(fees.gasPrice * 140000),
      tax: Math.ceil(order.amount * 0.005),
    });
  }, [fees, order, setFees]);

  // Set Total

  useEffect(() => {
    if (order.amount === 0 || fees.amount === 0 || total !== 0) return;
    const sum = Math.ceil(fees.amount + fees.tax + order.amount);
    setTotal(sum);
  }, [total, order, fees, setTotal]);

  // Get User Balance in Wallet for Graceful Extension Handling
  const [balance, setBalance] = useState<number | undefined>(undefined);
  const balanceLoading = useRef(false);

  useEffect(() => {
    let active = balanceLoading.current;

    if (balance || order.amount === 0 || !userWallet || active) return;
    (async () => {
      balanceLoading.current = true;
      await terra.bank.balance(userWallet).then((coins) => {
        const coinsData = coins
          .filter((coin) => coin.denom === order.denom)
          .toData();

        const balanceStr = coinsData.length > 0 ? coinsData[0].amount : "0";

        const balance = Number(balanceStr);
        setBalance(balance);

        if (balance < order.amount) {
          setError({
            code: 99,
            message: `Selected Wallet does not have enough ${order.currency
              .slice(0, -1)
              .toUpperCase()}T to make the payment`,
          });
        }
      });
    })();
  }, [balance, order, userWallet, terra.bank, setError]);

  return <></>;
}

export default PollTerra;
