import {
  MuiThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { paletteState } from "atoms";
import { useLocalStorage } from "hooks";
import React, { useLayoutEffect } from "react";
import { useRecoilState } from "recoil";
import { overrides, palette, shadows, typography } from "theme";
const queryString = require("query-string");

const SaturnTheme: React.FC = ({ children }) => {
  // Theme State Variables
  const userPrefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
    .matches;
  const [storedTheme] = useLocalStorage("atlas::theme");
  const [paletteType, setPaletteType] = useRecoilState(paletteState);
  const params = queryString.parse(window.location.search);
  const merchantThemePreference = params && params.theme;

  const isDarkMode = paletteType === "dark";

  const baseColor = isDarkMode
    ? palette.accents.black.main
    : palette.accents.white.main;

  const baseColorBackground = isDarkMode
    ? palette.accents.black.main
    : "#fbfdff";

  const theme = createMuiTheme({
    palette: {
      type: paletteType,
      common: {
        black: palette.accents.black.main,
        white: palette.accents.white.main,
      },
      primary: {
        light: isDarkMode
          ? palette.accents.teal.light
          : palette.accents.blue.light,
        main: isDarkMode
          ? palette.accents.teal.main
          : palette.accents.blue.main,
        dark: isDarkMode
          ? palette.accents.teal.dark
          : palette.accents.blue.dark,
        contrastText: baseColor,
      },
      secondary: {
        light: palette.accents.orange.light,
        main: palette.accents.orange.main,
        dark: palette.accents.orange.dark,
        contrastText: baseColor,
      },
      error: {
        light: palette.accents.red.light,
        main: palette.accents.red.dark,
        dark: palette.accents.red.light,
        contrastText: baseColor,
      },
      warning: {
        light: palette.accents.lightRed.light,
        main: palette.accents.lightRed.dark,
        dark: palette.accents.lightRed.light,
        contrastText: baseColor,
      },
      info: {
        light: palette.accents.blue.light,
        main: palette.accents.blue.dark,
        dark: palette.accents.blue.light,
        contrastText: baseColor,
      },
      success: {
        light: palette.accents.green.light,
        main: palette.accents.green.dark,
        dark: palette.accents.green.light,
        contrastText: baseColor,
      },
      text: {
        primary: isDarkMode
          ? palette.text.dark.primary
          : palette.text.light.primary,
        secondary: isDarkMode
          ? palette.text.dark.secondary
          : palette.text.light.secondary,
        disabled: isDarkMode
          ? palette.text.dark.disabled
          : palette.text.light.disabled,
        hint: baseColor,
      },
      background: {
        paper: baseColor,
        default: baseColorBackground,
      },
    },
    typography,
    shadows,
    overrides,
  });

  useLayoutEffect(() => {
    let isDarkMode = true;

    // Check for LocalStorage Theme Preference
    if (storedTheme) {
      isDarkMode = storedTheme === "dark";
    }

    // Check for Merchant Theme Preference
    if (merchantThemePreference && !storedTheme) {
      isDarkMode = params.theme === "dark";
    }

    // Set by user system preference
    if (!storedTheme && !merchantThemePreference) {
      isDarkMode = userPrefersDarkMode;
    }

    setPaletteType(isDarkMode ? "dark" : "light");
  }, [
    storedTheme,
    setPaletteType,
    merchantThemePreference,
    userPrefersDarkMode,
    params.theme,
  ]);

  useLayoutEffect(() => {
    const html = document.getElementById("root-html");
    if (paletteType === "dark" && html) return html.classList.remove("light");
    if (paletteType === "light" && html) return html.classList.add("light");
  }, [paletteType]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default SaturnTheme;
