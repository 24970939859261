import React, {SVGProps} from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="inherit" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="inherit"
          fillRule="nonzero"
          d="M8.398 5l.155.145 4.667 4.5.15.15v6.455c0 .966-.812 1.75-1.814 1.75H4.815C3.813 18 3 17.216 3 16.25v-9.5C3 5.784 3.813 5 4.815 5h3.583zm-.731 1.25H4.815a.51.51 0 00-.519.5v9.5c0 .276.232.5.519.5h6.74c.138 0 .27-.053.367-.146a.492.492 0 00.152-.354V10.5H9.482c-1.002 0-1.815-.784-1.815-1.75v-2.5zM12.027 2l.156.145 4.667 4.5.15.15v6.455c0 .966-.813 1.75-1.815 1.75h-.778v-1.25h.778c.138 0 .27-.052.367-.146a.492.492 0 00.152-.354V7.5H13.11c-1.002 0-1.815-.784-1.815-1.75v-2.5H8.444a.51.51 0 00-.518.5V4H6.63v-.25c0-.966.812-1.75 1.815-1.75h3.582z"
        ></path>
      </g>
    </svg>
  )
}

export default Icon
