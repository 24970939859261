import { Box, Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AnimationCheck, AnimationRocket } from "assets";
import {
  awaitingConfirmationState,
  orderIdState,
  orderState,
  redirectUrlState,
} from "atoms";
import { Extension, Loading, Manual, Summary, ThemeSwitch } from "components";
import { GridPrimary } from "pages";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { extension } from "terra";
import { cssGridPrimary } from "theme/styles";
import { SaturnButton, SaturnPaper } from "ui";

const queryString = require("query-string");

function Gateway() {
  // Hooks
  const location = useLocation();
  const classes = cssGridPrimary();

  // Atoms
  const setOrderId = useSetRecoilState(orderIdState);
  const [redirect, setRedirect] = useRecoilState(redirectUrlState);

  const [awaitingConfirmation, setAwaitingConfirmation] = useRecoilState(
    awaitingConfirmationState
  );
  const order = useRecoilValue(orderState);

  // State
  const [network, setNetwork] = useState<string | undefined>(undefined);

  const testnet = process.env.REACT_APP_BUILD_ENV === "testnet";

  // Load Query Params into State
  useEffect(() => {
    const params = queryString.parse(location.search);
    setOrderId(params.order_id);
    setRedirect(params.redirect);
  }, [location, setOrderId, setRedirect]);

  // Handle direct traffic to URL => saturn.money/atlas
  useEffect(() => {
    const params = queryString.parse(location.search);
    if (!params.redirect && !redirect) {
      window.location.replace("https://saturn.money/atlas");
    }
  }, [redirect, location.search]);

  // Get Extension Info
  useEffect(() => {
    if (network) return;
    (async () => {
      await extension.info().then((v) => setNetwork(v.name));
    })();
  }, [network]);

  function LoadingJSX() {
    return (
      <GridPrimary>
        <Box className={classes.fullWidth}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Loading />
            <Box mt={2}>
              <Link href={redirect}>Return to Store</Link>
            </Box>
          </Box>
        </Box>
        <ThemeSwitch />
      </GridPrimary>
    );
  }

  function CheckingJSX() {
    const [hidden, setHidden] = useState(true);

    const timer = setTimeout(() => {
      setHidden(false);
      return () => clearTimeout(timer);
    }, 70000);

    return (
      <GridPrimary>
        <Box className={classes.fullWidth}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box width={320} height={320}>
              <Lottie
                loop
                animationData={AnimationRocket}
                play
                style={{ width: 320, height: 320 }}
              />
            </Box>
            <Typography variant="body1" align="center">
              Payment Submitted Waiting for Confirmation
            </Typography>
            <Box hidden={hidden}>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="caption" display="block">
                  Payment taking too long?
                </Typography>
                <SaturnButton onClick={() => setAwaitingConfirmation(false)}>
                  Start again
                </SaturnButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <ThemeSwitch />
      </GridPrimary>
    );
  }

  function PaidJSX() {
    return (
      <GridPrimary>
        <Box className={classes.fullWidth}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box width={320} height={180}>
              <Lottie
                loop
                animationData={AnimationCheck}
                play
                style={{ width: 320, height: 180 }}
              />
            </Box>
            <Typography variant="body1">Payment Completed</Typography>
            <Typography variant="caption" display="block">
              Redirecting back to store...
            </Typography>
          </Box>
        </Box>
        <ThemeSwitch />
      </GridPrimary>
    );
  }

  function NetworkJSX({ message }: { message: string }) {
    return (
      <Box mb={4}>
        <SaturnPaper>
          <Box p={0}>
            <Alert
              variant="filled"
              severity="info"
              style={{ borderRadius: 24 }}
            >
              {message}
            </Alert>
          </Box>
        </SaturnPaper>
      </Box>
    );
  }

  function PaymentJSX() {
    return (
      <GridPrimary>
        <Box className={classes.summary}>
          <Summary />
        </Box>
        <Box className={classes.panel}>
          {testnet && (
            <NetworkJSX message="Make sure the payment is sent from Testnet" />
          )}

          {!testnet && network === "testnet" ? (
            <NetworkJSX message="Ensure your payment is sent from Mainnet" />
          ) : null}

          <Box mb={4}>
            <Extension />
          </Box>
          <Box>
            <Manual />
          </Box>
        </Box>
        <ThemeSwitch />
      </GridPrimary>
    );
  }

  const isLoading = order.status === "init";
  const isPaid = order.status === "paid";
  const isUnpaidAwaiting = order.status === "unpaid" && awaitingConfirmation;

  // Redirect back to Store
  useEffect(() => {
    if (!isPaid) return;

    const timer = setTimeout(() => {
      window.location.href = redirect ? redirect : order.redirect_uri;
    }, 3000);

    return () => clearTimeout(timer);
  }, [redirect, order.redirect_uri, isPaid]);

  if (isLoading) return <LoadingJSX />;

  if (isPaid) return <PaidJSX />;

  if (isUnpaidAwaiting) return <CheckingJSX />;

  return <PaymentJSX />;
}

export default Gateway;
