import {Box, Container, Fade} from '@material-ui/core'
import React from 'react'
import {cssGridPrimary} from 'theme/styles'

const GridPrimary: React.FC = ({children}) => {
  const classes = cssGridPrimary()
  return (
    <Fade in={true} timeout={1500} mountOnEnter unmountOnExit>
      <Container maxWidth="lg" className={classes.root}>
        <Box className={classes.container}>{children as React.ReactNode}</Box>
      </Container>
    </Fade>
  )
}

export default GridPrimary
