import {Box, IconButton, Tooltip, useTheme} from '@material-ui/core'
import {IconDarkMode, IconLightMode} from 'assets'
import {paletteState} from 'atoms'
import {useLocalStorage} from 'hooks'
import React from 'react'
import {useRecoilState} from 'recoil'
import {cssPayPage} from 'theme/styles'
import {SaturnPaper} from 'ui'

function ThemeSwitch() {
  const [theme, setTheme] = useRecoilState(paletteState)
  const [, setStoredTheme] = useLocalStorage('atlas::theme')

  const colorTheme = useTheme()

  const handleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(newTheme)
    setStoredTheme(newTheme)
  }

  const classes = cssPayPage()

  return (
    <Box className={classes.switch}>
      <SaturnPaper>
        <Tooltip
          title={`Change to ${theme === 'dark' ? 'Light Mode' : 'Dark Mode'}`}
          placement="left"
        >
          <IconButton onClick={handleTheme} color="secondary">
            {theme === 'dark' && (
              <IconLightMode
                style={{fill: colorTheme.palette.secondary.light}}
                width={26}
                height={26}
              />
            )}
            {theme === 'light' && (
              <IconDarkMode
                style={{fill: colorTheme.palette.text.primary}}
                width={26}
                height={26}
              />
            )}
          </IconButton>
        </Tooltip>
      </SaturnPaper>
    </Box>
  )
}

export default ThemeSwitch
