import { orderIdState, orderRefetchIntervalState, orderState } from "atoms";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Saturn from "typescript";

function PollOrder() {
  const orderId = useRecoilValue(orderIdState);
  const [refetchInterval, setRefetchInterval] = useRecoilState(
    orderRefetchIntervalState
  );
  const setOrder = useSetRecoilState(orderState);

  const { data } = useQuery<Saturn.models.Order, Error>(
    "order",
    () =>
      fetch(
        `${process.env.REACT_APP_ATLAS_API_ENDPOINT}/order?order_id=${orderId}`
      ).then((r) => r.json()),
    {
      enabled: orderId ? true : false,
      refetchOnWindowFocus: true,
      refetchInterval,
    }
  );

  useEffect(() => {
    if (!data) return;
    setOrder(data);
    if (data.status === "paid") return setRefetchInterval(false);
  }, [data, setOrder, setRefetchInterval]);

  return <></>;
}

export default PollOrder;
