import {Button, withTheme} from '@material-ui/core'
import styled from 'styled-components'

const TethysButton = withTheme(styled(Button)`
  &.MuiButton-root {
    font-weight: 700;
    border-radius: 8px;
  }
  &.MuiButton-containedPrimary {
    background: ${(props) => props.theme.palette.primary.main};
    transition: all 0.5s ease-in-out;
    &:hover {
      background: ${(props) => props.theme.palette.primary.light};
    }
  }
  &.MuiButton-outlined {
    border: none;
  }
  &.Mui-disabled {
    opacity: 0.4;
  }
`)

export default TethysButton
