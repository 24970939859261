import {
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MsgSend } from "@terra-money/terra.js";
import {
  awaitingConfirmationState,
  orderErrorState,
  orderFeeState,
  orderState,
} from "atoms";
import { WalletContext } from "providers";
import React, { useContext } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { extension } from "terra";
import { palette } from "theme";
import { SaturnButton, SaturnGradientPaper } from "ui";

function PayExtension() {
  // useWallet Hook for Terra Station Extension
  const { address: sender, connect, installed } = useContext(WalletContext);

  // Theme Hooks
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // Atoms
  const [error, setError] = useRecoilState(orderErrorState);
  const order = useRecoilValue(orderState);
  const fees = useRecoilValue(orderFeeState);
  const setAwaitConfirmation = useSetRecoilState(awaitingConfirmationState);

  // Perform the Payment
  const makePayment = async () => {
    if (!order || !sender || fees.amount === 0) return;

    // Build Message for Terra
    const msgs = new MsgSend(sender, order.wallet, {
      [order.denom]: order.amount,
    });

    // Send Payment Instruction to Extension
    await extension
      .post(
        {
          msgs: [msgs],
          memo: order.id,
        },
        {
          ...fees,
        },
        {
          denom: order.denom,
        }
      )
      .then((response) => {
        if (response.error) {
          setAwaitConfirmation(false);
          if (response.error.code === 1) return;
          return setError(response.error);
        }
        return setAwaitConfirmation(true);
      });
  };

  return (
    <SaturnGradientPaper>
      <Box p={4}>
        <Box
          display="flex"
          flexDirection={sm ? "column" : "row"}
          justifyContent="space-between"
          alignItems={sm ? "flex-start" : "center"}
        >
          <Box>
            <Typography variant="body1">
              Pay with{" "}
              <Link
                href="https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp?hl=en"
                target="_blank"
                rel="noopener"
              >
                Terra Station Extension
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Via the Google Chrome Plugin
            </Typography>
          </Box>
          <Box ml={sm ? 0 : 4} mt={sm ? 2 : 0}>
            {!installed && (
              <SaturnButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  window.open(
                    "https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp?hl=en",
                    "_blank"
                  );
                }}
              >
                Install
              </SaturnButton>
            )}

            {installed && !sender ? (
              <SaturnButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => connect()}
              >
                Connect
              </SaturnButton>
            ) : null}

            {installed && sender ? (
              <SaturnButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => makePayment()}
              >
                Pay
              </SaturnButton>
            ) : null}
          </Box>
        </Box>
        {error && error.message ? (
          <Box
            p={2}
            mt={2}
            style={{
              background: theme.palette.background.default,
              borderRadius: 8,
              border:
                theme.palette.type === "dark"
                  ? "none"
                  : `1px solid ${theme.palette.text.secondary}20`,
            }}
          >
            <Typography
              color="inherit"
              style={{
                color:
                  theme.palette.type === "dark"
                    ? palette.accents.orange.main
                    : palette.accents.orange.dark,
              }}
            >
              {"⚠️ "}
              {error.code === 99 && error.message}
              {error.code === 2 && "Insufficient funds in wallet"}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </SaturnGradientPaper>
  );
}

export default PayExtension;
